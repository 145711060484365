import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { TransitionContext } from './context/transition-context'

import FadeIn from './fade-in'

const Button = ({ type, to, text }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  switch (type) {
    case 'link':
      return (
        <FadeIn>
          <ButtonMainLink
            to={`/${to}`}
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/${to}`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            <ButtonText>{text}</ButtonText>
            <Fill />
          </ButtonMainLink>
        </FadeIn>
      )
    case 'a':
      return (
        <ButtonMainA href={to}>
          <ButtonText>{text}</ButtonText>
          <Fill />
        </ButtonMainA>
      )
    case 'button':
      return (
        <ButtonMainButton>
          <ButtonText>{text}</ButtonText>
          <Fill />
        </ButtonMainButton>
      )
    default:
      return null
  }
}

const Fill = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(101%);
  transform-origin: bottom;
  background-color: ${props => props.theme.colours.orange};
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  will-change: transform;
  z-index: 1;
`

const ButtonText = styled.span`
  position: relative;
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.2rem;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  transition: color 0.3s ease;
  z-index: 5;

  @media (max-width: 650px) {
    font-size: 2rem;
  }

  &:before {
    display: none;
  }
`

const buttonStyles = css`
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 2rem 2.5rem 1.7rem;
  color: ${props => props.theme.colours.orange};
  border: 2px solid ${props => props.theme.colours.orange};
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 1000px) {
    padding: 2.2rem 1.7rem 1.7rem;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    ${Fill} {
      transform: translateY(0);
    }

    ${ButtonText} {
      color: #fff;
    }
  }
`

const ButtonMainLink = styled(Link)`
  ${buttonStyles}
`

const ButtonMainA = styled.a`
  ${buttonStyles}
`

const ButtonMainButton = styled.button`
  ${buttonStyles}
`

Button.defaultProps = {
  type: 'link',
  to: '/',
  text: 'find out more',
  click: null
}

export default Button
