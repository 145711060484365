import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { animated, config, useTrail, useSpring } from 'react-spring'
import usePortal from 'react-useportal'
import { isMobile } from 'react-device-detect'
import Parallax from '../parallax'
import InView from '../in-view'

import CrossSvg from '../../images/cross.svg'

export const fragment = graphql`
  fragment AboutTeamFragment on WordPress_Page_Aboutfields {
    team {
      title
      teamMember {
        name
        position
        description
        image {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const AboutTeam = ({ title, teamMember }) => {
  const ref = useRef()
  const [currentPerson, setCurrentPerson] = useState(0)
  const [animationToggle, setAnimationToggle] = useState(false)
  const [fadeToggle, setFadeToggle] = useState(false)
  const teamMembers = teamMember
  const { openPortal, closePortal, isOpen, Portal } = usePortal()

  const trail = useTrail(teamMembers.length, {
    to: {
      mainTransform: animationToggle ? `0` : `50`,
      outerTransform: animationToggle ? `0` : `101`,
      innerTransform: animationToggle ? `0` : `-90`
    },
    from: {
      mainTransform: `50`,
      outerTransform: `101`,
      innerTransform: `-90`
    },
    config: config.slow
  })

  const fadeBio = useSpring({
    to: {
      opacity: fadeToggle ? 0 : 1
    },
    config: {
      duration: 300
    }
  })

  const handleTeamClick = index => {
    if (isMobile) {
      openPortal()
      setCurrentPerson(index)
    } else {
      setFadeToggle(true)

      setTimeout(() => {
        setCurrentPerson(index)
        setFadeToggle(false)
      }, 300)
    }
  }

  return (
    <>
      <Outer>
        <ParallaxTitle>
          <Parallax speed={5}>
            <h2>{title}</h2>
          </Parallax>
        </ParallaxTitle>
        <TeamBlock ref={ref}>
          <InView element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
            <WrapMember>
              {trail.map(({ mainTransform, outerTransform, innerTransform }, index) => (
                <Member
                  key={index}
                  onClick={() => handleTeamClick(index)}
                  style={{ transform: mainTransform.interpolate(y => `translate3d(0,${y}px,0)`) }}
                >
                  <MemberTitles>
                    <h3>{teamMember[index].name}</h3>
                    <p>{teamMember[index].position}</p>
                  </MemberTitles>
                  <MemberOuter
                    style={{
                      transform: outerTransform.interpolate(y => `translate3d(0,${y}px,0)`)
                    }}
                  >
                    <MemberInner
                      style={{
                        transform: innerTransform.interpolate(y => `translate3d(0,${y}px,0)`)
                      }}
                    >
                      <MemberPhoto
                        fluid={teamMember[index].image.imageFile.childImageSharp.fluid}
                      />
                    </MemberInner>
                  </MemberOuter>
                </Member>
              ))}
            </WrapMember>
          </InView>

          <WrapContent>
            <Person style={fadeBio}>
              <h3>{teamMember[currentPerson].name}</h3>
              <Position>{teamMember[currentPerson].position}</Position>
              <Bio>{teamMember[currentPerson].description}</Bio>
            </Person>
          </WrapContent>
        </TeamBlock>
      </Outer>

      <Portal>
        <Modal className={isOpen ? 'active' : ''}>
          <ModalContents>
            <Close onClick={closePortal}>
              <CrossSvg />
            </Close>
            <ModalImage>
              <GatsbyImage
                fluid={teamMember[currentPerson].image.imageFile.childImageSharp.fluid}
                style={{ position: 'absolute' }}
              />
            </ModalImage>
            <p>
              <strong>{teamMember[currentPerson].name}</strong>
            </p>
            <small>{teamMember[currentPerson].position}</small>
            <p>{teamMember[currentPerson].description}</p>
          </ModalContents>
          <ModalBackground />
        </Modal>
      </Portal>
    </>
  )
}

const Outer = styled.div`
  position: relative;
  margin-top: 10rem;
  padding: 20rem 10.5% 10rem 16.5%;

  @media (max-width: 1260px) {
    padding: 100px 7.5% 0;
  }

  @media (max-width: 650px) {
    padding: 85px 30px 65px 30px;
  }

  h2 {
    width: 50%;
    color: ${props => props.theme.colours.orange};
    font-family: ${props => props.theme.fonts.butler};
    font-size: 9rem;
    line-height: 0.95;
    position: absolute;
    top: -7.5%;
    left: -12%;
    transform: rotate(-90deg) translate(-15%, -35%);

    @media (max-width: 1260px) {
      position: relative;
      top: 50%;
      left: 15px;
      transform: rotate(-90deg) translate(0, 0);
      transform-origin: top left;
    }

    @media (max-width: 767px) {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      transform: rotate(0) translate(0, 0);
    }
  }
`

const ParallaxTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 767px) {
    position: relative;
    transform: translate3d(0, 0, 0) !important;
  }
`

const TeamBlock = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 4rem 0 7.5rem;
`

const WrapMember = styled.div`
  width: calc(60% - 3.5rem);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 3.5rem;

  @media (max-width: 650px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const MemberPhoto = styled(Img)`
  transition: transform 1s ease;
  will-change: transform;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(213, 101, 27, 0);
    transition: background-color 1s ease;
    z-index: 2;
  }
`

const Member = styled(animated.div)`
  width: 100%;
  position: relative;
  margin-bottom: 3.5rem;
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    padding-bottom: 90%;
  }

  &:hover {
    ${MemberPhoto} {
      transform: scale(1.1);

      &::before {
        background-color: rgba(213, 101, 27, 0.3);
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 22px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:nth-child(odd) {
    top: -50px;

    @media (max-width: 767px) {
      transform: translateY(0);
      top: 0;
    }
  }
`

const MemberOuter = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const MemberInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const MemberTitles = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 2;

  @media (max-width: 800px) {
    bottom: 1rem;
    left: 1rem;
  }

  @media (max-width: 650px) {
    bottom: 2rem;
    left: 2rem;
  }

  h3 {
    font-family: ${props => props.theme.fonts.butler};
    font-size: 2.8rem;
    margin-bottom: 0.5rem;
    letter-spacing: -0.1rem;

    @media (max-width: 800px) {
      font-size: 2rem;
      margin-bottom: 0;
    }

    @media (max-width: 800px) {
      font-size: 2.8rem;
      margin-bottom: 0.5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    margin-bottom: 0;

    @media (max-width: 800px) {
      letter-spacing: 0.2rem;
    }

    @media (max-width: 650px) {
      font-size: 1.8rem;
      letter-spacing: 0.4rem;
    }
  }
`

const WrapContent = styled.div`
  position: relative;
  width: 40%;
  margin-left: 3.5rem;

  @media (max-width: 650px) {
    display: none;
  }
`

const Person = styled(animated.div)`
  position: relative;

  h3 {
    font-family: ${props => props.theme.fonts.butler};
    font-size: 5.5rem;
    color: ${props => props.theme.colours.orange};
  }

  p {
    white-space: pre-wrap;
  }
`

const Position = styled.p`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
`

const Bio = styled.p`
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 200;
  font-size: 1.5rem;
  line-height: 1.8;

  @media (max-width: 767px) {
    font-size: 16px;
    width: 100%;
  }
`

const ModalContents = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 30px;
  max-height: 95%;
  transform: translate3d(0, 200px, 0);
  transition: transform 0.8s ease;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;

  .gatsby-image-wrapper {
    &::before {
      content: '';
      display: block;
      padding-bottom: 86.7758%;
    }
  }
`

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
`

const Modal = styled(animated.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 0 30px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.8s ease;
  overflow: hidden;
  z-index: 100;

  &.active {
    @media (max-width: 650px) {
      opacity: 1;
      pointer-events: all;
    }

    ${ModalContents} {
      transform: translate3d(0, 0, 0);
    }
  }

  p {
    color: ${props => props.theme.colours.darkGrey};
    margin-bottom: 0;
    white-space: pre-wrap;

    strong {
      display: block;
      font-family: ${props => props.theme.fonts.butler};
      font-size: 5.8rem;
      letter-spacing: -0.1rem;
      color: ${props => props.theme.colours.orange};
      margin-top: 2rem;
    }
  }

  small {
    color: ${props => props.theme.colours.darkGrey};
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 500;
    font-size: 1.8rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }
`

const ModalImage = styled.div`
  position: relative;
  width: 100%;
  margin-top: 11rem;

  &::before {
    display: block;
    content: '';
    padding-bottom: 86%;
  }
`

const GatsbyImage = styled(Img)`
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Close = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  padding: 30px;
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;

  svg {
    width: 20px;
    height: 20px;
  }
`

export default AboutTeam
