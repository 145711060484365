import React, { useState, useContext, useEffect, useRef } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { ScrollbarContext } from './context/scrollbar-context'

const setLightTheme = () => {
  if (typeof document !== `undefined`) {
    document.documentElement.style.setProperty('--primary', '#242424')
  }
}

const setDarkTheme = () => {
  if (typeof document !== `undefined`) {
    document.documentElement.style.setProperty('--primary', '#ffffff')
  }
}

const BackgroundImage = ({ image }) => {
  const [scrollbar] = useContext(ScrollbarContext)
  const [toggle, setToggle] = useState(false)
  const containerRef = useRef()
  const imageRef = useRef()

  const fadeAnimation = useSpring({
    opacity: toggle ? 1 : 0,
    config: {
      duration: 500
    }
  })

  useEffect(() => {
    const fixedPosition = ({ offset }) => {
      if (imageRef.current) {
        imageRef.current.style.top = `${offset.y}px`
      }
    }

    // Hooks into the Smooth Scrollbar instance that's passed as context
    // to create an artificially fixed element, sync to the current
    // Smooth Scrollbar offset
    if (scrollbar) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
  }, [scrollbar])

  useEffect(() => {
    const thresholdArray = steps =>
      Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0)

    let previousY = 0
    let previousRatio = 0

    const handleIntersect = entries => {
      entries.forEach(entry => {
        const currentY = entry.boundingClientRect.top
        const currentRatio = entry.intersectionRatio
        const { isIntersecting } = entry

        // Scrolling down/up
        if (currentY < previousY) {
          if (currentRatio > previousRatio && isIntersecting) {
            // Scrolling down enter
            setToggle(true)
            setDarkTheme()
          } else {
            // Scrolling down leave
            setToggle(true)
            setDarkTheme()
          }
        } else if (currentY > previousY && isIntersecting) {
          if (currentRatio < previousRatio) {
            // Scrolling up leave
            setToggle(false)
            setLightTheme()
          } else {
            // Scrolling up enter
            setToggle(true)
            setLightTheme()
          }
        }

        previousY = currentY
        previousRatio = currentRatio
      })
    }

    const observer = new IntersectionObserver(handleIntersect, {
      threshold: thresholdArray(10),
      rootMargin: '50%'
    })

    observer.observe(containerRef.current)
  }, [])

  return (
    <>
      <Trigger ref={containerRef} />
      <ImageContainer style={fadeAnimation} ref={imageRef}>
        <Image fluid={image} style={{ position: 'absolute' }} />
      </ImageContainer>
    </>
  )
}

export default BackgroundImage

const Trigger = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  transform: translateY(50%);
  pointer-events: none;
`

const ImageContainer = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  will-change: opacity;
  z-index: -1;
`

const Image = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: #000;
    opacity: 0.85;
  }
`
