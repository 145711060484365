import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Parallax from '../parallax'
import FadeIn from '../fade-in'
import MaskedImage from '../masked-image'

export const fragment = graphql`
  fragment AboutMainContentFragment on WordPress_Page_Aboutfields {
    mainContent {
      smallTitle
      title
      titleRight
      text
      backgroundColour
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const MainContent = ({ smallTitle, title, image, text, titleRight }) => (
  <Outer>
    <IntroBlock>
      <BlockLeft>
        <FadeIn>
          <SmallTitle>{smallTitle}</SmallTitle>
        </FadeIn>
        <FadeIn>
          <Line />
        </FadeIn>
        <FadeIn>
          <h2>{title}</h2>
        </FadeIn>
        <FadeIn>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </FadeIn>
      </BlockLeft>

      <BlockRight>
        <Image>
          <MaskedImage fluid={image.imageFile.childImageSharp.fluid} />
        </Image>
        <ParallaxTitle>
          <Parallax speed={5}>
            <p>{titleRight}</p>
          </Parallax>
        </ParallaxTitle>
      </BlockRight>
    </IntroBlock>
  </Outer>
)

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 17rem 0 0 16.5%;
  z-index: 5;

  @media (max-width: 1260px) {
    padding: 5rem 12.5%;
  }

  @media (max-width: 767px) {
    padding: 0 30px 65px;
  }

  @media (max-width: 650px) {
    padding: 65px 20px;
  }
`

const SmallTitle = styled.span`
  position: absolute;
  top: 50%;
  display: inline-block;
  color: ${props => props.theme.colours.darkGrey};
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  line-height: 2;
  margin-bottom: 4.5rem;
  transform: rotate(0.75turn) translate3d(-140%, -790%, 0);

  &:before {
    content: '';
    display: inline-block;
    background: ${props => props.theme.colours.orange};
    width: 0.9rem;
    height: 0.9rem;
    margin-right: 1.5rem;
  }
`

const IntroBlock = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`

const BlockLeft = styled.div`
  position: relative;
  width: 45%;
  margin: 15rem 0;

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (max-width: 1260px) {
    margin: 0;
  }

  h2 {
    width: 100%;
    color: ${props => props.theme.colours.orange};
    font-size: 4rem;
    font-family: ${props => props.theme.fonts.butler};
    line-height: 1.25;
    margin-bottom: 5rem;
    letter-spacing: -0.1rem;

    @media (max-width: 767px) {
      margin-top: 20rem;
    }
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 200;
    font-size: 1.5rem;
    color: ${props => props.theme.colours.darkGrey};
    color: var(--primary);
    line-height: 2.2;
    margin-bottom: 4rem;

    @media (max-width: 767px) {
      font-size: 16px;
      width: 100%;
    }

    &:last-child {
      margin-bottom: 6rem;
    }
  }
`

const BlockRight = styled.div`
  position: relative;
  width: 45%;
  pointer-events: none;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 50px;
    transform: translateX(30px);
  }

  p {
    width: 150%;
    color: ${props => props.theme.colours.orange};
    font-size: 9.5em;
    font-family: ${props => props.theme.fonts.butler};
    line-height: 1.25;
    transform: rotate(0.75turn) translate3d(10%, -50%, 0);

    @media (max-width: 1400px) {
      font-size: 7rem;
    }

    @media (max-width: 850px) {
      font-size: 6.5rem;
      max-width: 500px;
    }

    @media (max-width: 767px) {
      transform: rotate(0.75turn) translate3d(10%, -176%, 0);
    }

    @media (max-width: 650px) {
      transform: rotate(0.75turn) translate3d(10%, -207%, 0);
    }

    @media (max-width: 350px) {
      transform: rotate(0.75turn) translate3d(10%, -158%, 0);
    }
  }
`

const Image = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: 90%;
  }
`

const ParallaxTitle = styled.div`
  position: absolute;
  top: 42%;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 78ch;

  @media (max-width: 1400px) {
    top: 25%;
  }

  @media (max-width: 767px) {
  }
`

const Line = styled.div`
  background: ${props => props.theme.colours.lightGrey};
  height: 1px;
  width: 5.5rem;
  margin-bottom: 6rem;
  display: block;

  @media (max-width: 767px) {
    margin: 30px 0 50px 0;
    display: none;
  }
`

export default MainContent
