import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import AboutIntro from '../components/about/intro'
import MainContent from '../components/about/main-content'
import ImageCarousel from '../components/about/image-carousel'
import AboutTeam from '../components/about/team'
import Press from '../components/press'
import Seo from '../components/seo'

export const fragment = graphql`
  fragment AboutHeroFragment on WordPress_Page_Aboutfields {
    hero {
      title
      imageCaption
      imageBack {
        focalPoint
        focalPointMobile
        image {
          altText
          sourceUrl
          mediaItemId
          modified
          id
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      imageFront {
        focalPoint
        focalPointMobile
        image {
          altText
          sourceUrl
          mediaItemId
          modified
          id
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const About = ({ data, pageContext }) => {
  const [scrollToPress, setScrollToPress] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.location.hash === '#press') {
        setScrollToPress(true)
      }
    }
  }, [])

  return (
    <Layout>
      <Seo pageTitle={pageContext.title} {...data.wordPress.pageBy.seo} />
      <Hero {...data.wordPress.pageBy.aboutFields.hero} />
      <AboutIntro {...data.wordPress.pageBy.aboutFields.intro} />
      <MainContent {...data.wordPress.pageBy.aboutFields.mainContent} />
      <ImageCarousel {...data.wordPress.pageBy.aboutFields.imageCarousel} />
      <AboutTeam {...data.wordPress.pageBy.aboutFields.team} />
      <Press scrollToPress={scrollToPress} />
    </Layout>
  )
}

export default About

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        aboutFields {
          ...AboutHeroFragment
          ...AboutIntroFragment
          ...AboutMainContentFragment
          ...AboutCarouselFragment
          ...AboutTeamFragment
        }
      }
    }
  }
`
