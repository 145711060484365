import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import FadeIn from '../fade-in'
import BackgroundColor from '../background-color'
import Button from '../button'
import MaskedImage from '../masked-image'

export const fragment = graphql`
  fragment AboutIntroFragment on WordPress_Page_Aboutfields {
    intro {
      smallTitle
      title
      text
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      buttonLabel
      buttonLink {
        ... on WordPress_Page {
          slug
        }
      }
    }
  }
`

const AboutIntro = ({ smallTitle, title, image, text, buttonLink, buttonLabel }) => (
  <Outer>
    <IntroBlock>
      <BlockLeft>
        <FadeIn>
          <SmallTitle>{smallTitle}</SmallTitle>
        </FadeIn>
        <FadeIn>
          <h2>{title}</h2>
        </FadeIn>
        <Image>
          <MaskedImage fluid={image.imageFile.childImageSharp.fluid} />
        </Image>
      </BlockLeft>

      <BlockRight>
        <FadeIn>
          <Line />
        </FadeIn>
        <FadeIn>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </FadeIn>
        <Button type="link" to={buttonLink.slug} text={buttonLabel} />
      </BlockRight>
    </IntroBlock>
    <BackgroundColor />
  </Outer>
)

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 17rem 16.5%;

  @media (max-width: 767px) {
    padding: 0 30px 65px;
  }
`

const SmallTitle = styled.span`
  display: block;
  color: ${props => props.theme.colours.white};
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  line-height: 2;
  margin-bottom: 4.5rem;

  &:before {
    content: '';
    display: inline-block;
    background: ${props => props.theme.colours.orange};
    width: 0.9rem;
    height: 0.9rem;
    margin-right: 1.5rem;
  }
`

const IntroBlock = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const BlockLeft = styled.div`
  width: 45%;

  @media (max-width: 767px) {
    width: 100%;
  }

  h2 {
    width: 100%;
    color: ${props => props.theme.colours.white};
    color: var(--primary);
    font-size: 4rem;
    font-family: ${props => props.theme.fonts.butler};
    line-height: 1.25;
    margin-bottom: 10rem;
  }
`

const Image = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: 126%;
  }
`

const BlockRight = styled.div`
  width: 45%;
  margin-top: 20rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 50px;
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 200;
    font-size: 1.5rem;
    color: ${props => props.theme.colours.white};
    color: var(--primary);
    line-height: 2.2;
    margin-bottom: 4rem;

    @media (max-width: 767px) {
      font-size: 16px;
      width: 100%;
    }

    &:last-child {
      margin-bottom: 6rem;
    }
  }
`

const Line = styled.div`
  background: ${props => props.theme.colours.lightGrey};
  height: 1px;
  width: 5.5rem;
  margin-bottom: 6rem;
  display: block;

  @media (max-width: 767px) {
    margin: 30px 0 50px 0;
    display: none;
  }
`

export default AboutIntro
