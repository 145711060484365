import React, { useContext, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { useSpring, useChain, useTrail, animated, interpolate } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image'
import BezierEasing from 'bezier-easing'
import Parallax from './parallax'
import { PreloaderContext } from './context/preloader-context'
import { TransitionContext } from './context/transition-context'

import LogoSvg from '../images/logo.svg'

export const fragment = graphql`
  fragment AvailabilitiesHeroFragment on WordPress_Page_Availabilitiesfields {
    hero {
      title
      imageCaption
      imageBack {
        focalPoint
        focalPointMobile
        image {
          altText
          sourceUrl
          mediaItemId
          modified
          id
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      imageFront {
        focalPoint
        focalPointMobile
        image {
          altText
          sourceUrl
          mediaItemId
          modified
          id
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const Hero = ({ title, imageCaption, imageBack, imageFront }) => {
  const [preloaderStatus] = useContext(PreloaderContext)
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  const backImageIntroAnimationRef = useRef()
  const backImageIntroAnimation = useSpring({
    ref: backImageIntroAnimationRef,
    from: {
      opacity: 0,
      transform: 'translate3d(-10%,0,0)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)'
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const FrontImageOuterAnimationRef = useRef()
  const FrontImageOuterAnimation = useSpring({
    ref: FrontImageOuterAnimationRef,
    from: {
      transform: `translate3d(101%, 0, 0)`
    },
    to: {
      transform: `translate3d(0%, 0, 0)`
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const FrontImageInnerAnimationRef = useRef()
  const FrontImageInnerAnimation = useSpring({
    ref: FrontImageInnerAnimationRef,
    from: {
      transform: `translate3d(0%, 0, 0)`
    },
    to: {
      transform: `translate3d(0%, 0, 0)`
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const logoAnimationRef = useRef()
  const logoAnimation = useSpring({
    ref: logoAnimationRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  })

  const slideTextAnimationRef = useRef()
  const slideTextAnimation = useSpring({
    ref: slideTextAnimationRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  })

  const lineAnimationRef = useRef()
  const lineAnimation = useSpring({
    ref: lineAnimationRef,
    from: { transform: 'scaleX(0)' },
    to: { transform: 'scaleX(1)' },
    config: {
      duration: 500,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  // Split the text string into an array of words
  const splitText = title.split(' ')

  const wordAnimationRef = useRef()
  const wordAnimation = useTrail(splitText.length, {
    ref: wordAnimationRef,
    to: {
      y: 0,
      rotate: 0,
      opacity: 1
    },
    from: { rotate: 5, y: 30, opacity: 0 },
    config: {
      mass: 10,
      tension: 1000,
      friction: 200
    }
  })

  useChain(
    !preloaderStatus.shouldPlay && preloaderStatus.finished
      ? [
          backImageIntroAnimationRef,
          FrontImageOuterAnimationRef,
          FrontImageInnerAnimationRef,
          wordAnimationRef,
          slideTextAnimationRef,
          lineAnimationRef,
          logoAnimationRef
        ]
      : [],
    [0, 0.5, 1, 1, 1.5, 2]
  )

  const WipeAnimation = useSpring({
    from: { transform: 'translate3d(0%, 0, 0)' },
    to: { transform: 'translate3d(100%, 0, 0)' },
    config: {
      duration: 1200,
      easing: BezierEasing(0.785, 0.135, 0.15, 0.86)
    }
  })

  return (
    <Outer>
      <Wipe style={WipeAnimation} />
      <Inner>
        <Logo style={logoAnimation} mobile={false}>
          <Link
            to="/"
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            <LogoSvg />
          </Link>
        </Logo>
        <Heading>
          <Parallax speed={4}>
            <Logo style={logoAnimation} mobile>
              <Link
                to="/"
                onClick={event => {
                  event.preventDefault()
                  setTransitionActive(true)
                  setTimeout(() => {
                    navigate(`/`)
                    setTransitionActive(false)
                  }, 500)
                }}
              >
                <LogoSvg />
              </Link>
            </Logo>
            <animated.h1>
              {wordAnimation.map(({ rotate, y, ...rest }, index) => (
                <Word
                  key={[index]}
                  style={{
                    ...rest,
                    transform: interpolate(
                      [y, rotate],
                      (y, rotate) => `translateY(${y}px) rotate(${rotate}deg)`
                    )
                  }}
                >
                  {splitText[index]}&nbsp;
                </Word>
              ))}
              <Line style={lineAnimation} />
            </animated.h1>
          </Parallax>
        </Heading>
        <SlideText>
          <animated.p style={slideTextAnimation}>{imageCaption}</animated.p>
        </SlideText>
        <ImageFrontContainer>
          <ImageFrontOuter style={FrontImageOuterAnimation}>
            <ImageFrontInner style={FrontImageInnerAnimation}>
              <Parallax speed={10} position="fill">
                <ImageFront>
                  <GatsbyImage
                    className="front-swiper-image"
                    fluid={imageFront.image.imageFile.childImageSharp.fluid}
                    alt={imageFront.image.alt}
                    loading="eager"
                  />
                </ImageFront>
              </Parallax>
            </ImageFrontInner>
          </ImageFrontOuter>
        </ImageFrontContainer>
      </Inner>
      <ImageBackContainer style={backImageIntroAnimation}>
        <ImageBack center speed={0}>
          <GatsbyImage
            className="front-swiper-image"
            fluid={imageBack.image.imageFile.childImageSharp.fluid}
            alt={imageBack.image.alt}
            fadeIn
          />
        </ImageBack>
      </ImageBackContainer>
    </Outer>
  )
}

export default Hero

const Outer = styled.section`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 1200px) {
    height: 100vh;
  }
`

const Inner = styled.section`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 67%;
  z-index: 2;

  @media (max-width: 1260px) {
    width: 85%;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 20px;
  }
`

const Logo = styled(animated.div)`
  display: ${props => (props.mobile ? 'none' : 'block')};
  position: absolute;
  top: 20%;
  left: 0;
  transform: translateX(-30%);
  z-index: 10;

  @media (max-width: 1260px) {
    transform: translateX(0);
  }

  @media (max-width: 800px) {
    display: ${props => (props.mobile ? 'block' : 'none')};
    position: relative;
    top: initial;
    margin-bottom: 2rem;
  }

  > div {
    @media (max-width: 800px) {
      transform: translate3d(0, 0, 0) !important;
    }
  }

  svg {
    width: 30rem;

    @media (max-width: 1000px) {
      width: 35rem;
    }

    @media (max-width: 650px) {
      width: 260px;
    }
  }
`

const Heading = styled.div`
  position: relative;
  font-size: 10rem;
  width: 100%;
  max-width: 70rem;
  color: #fff;
  word-wrap: normal;
  z-index: 10;

  @media (max-width: 800px) {
    width: 100%;
  }

  h1 {
    font-size: 10rem;
    letter-spacing: -0.2rem;

    @media (max-width: 800px) {
      margin-top: 0;
      transform: translateY(0);
      margin-bottom: 0;
    }

    @media (max-width: 650px) {
      font-size: 7.8rem;
    }

    @media (max-width: 380px) {
      font-size: 7rem;
      width: 100%;
    }
  }
`

const Line = styled(animated.div)`
  position: absolute;
  bottom: -30%;
  left: 0;
  width: 5.7rem;
  height: 1px;
  background-color: #fff;
  transform-origin: left;

  @media (max-width: 800px) {
    position: relative;
    bottom: 0;
    margin-top: 5rem;
  }
`

const SlideText = styled.div`
  position: absolute;
  top: 5vh;
  right: -1.8rem;
  transform: translateX(calc(100% + 1.8rem)) rotate(90deg);
  transform-origin: left top;
  z-index: 2;

  @media (max-width: 800px) {
    top: initial;
    right: 8%;
    bottom: 2.5%;
    width: 100%;
    text-align: right;
    transform: translateX(0) rotate(0);
    height: 42px;
    overflow: hidden;
    z-index: 10;
  }

  @media (max-width: 650px) {
    display: none;
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0.4rem;
    line-height: 3;
    text-transform: uppercase;

    @media (max-width: 800px) {
      font-size: 2rem;
    }

    color: #fff;
  }
`

const ImageBackContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  pointer-events: none;
  will-change: transform;
  z-index: 1;

  @media (max-width: 800px) {
    display: none;
  }
`

const ImageBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 5;
  }

  .swiper-wrapper {
    height: 90vh;
    will-change: transform;
    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .swiper-slide,
  .swiper-slide-duplicate {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`

const GatsbyImage = styled(Img)`
  width: 100%;
  height: 100%;
  will-change: transform;

  img {
    opacity: 1 !important;
  }
`

const ImageFrontContainer = styled.section`
  position: absolute;
  bottom: 5vh;
  right: 0;
  width: 56.5%;
  height: 90vh;
  overflow: hidden;
  pointer-events: none;
  transform-origin: right;
  overflow: hidden;
  z-index: 5;

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    bottom: 0;
  }
`

const ImageFrontOuter = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  overflow: hidden;

  > div {
    @media (max-width: 800px) {
      transform: translate3d(0, 0, 0) !important;
    }
  }
`

const ImageFrontInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
`

const ImageFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 1200px) {
    height: 95vh;
  }

  @media (max-width: 800px) {
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 95vh;
      background-color: rgba(0, 0, 0, 0.65);
      z-index: 5;
    }
  }

  .swiper-wrapper {
    height: 90vh;
    will-change: transform;
    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1);

    @media (max-width: 800px) {
      height: 95vh;
    }
  }

  .swiper-slide,
  .swiper-slide-duplicate {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`

const Word = styled(animated.span)`
  display: inline-block;
  transform-origin: left bottom;
  opacity: 0;
  will-change: transform;
`

const Wipe = styled(animated.aside)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-color: ${props => props.theme.colours.darkGrey};
  z-index: 50;
`
