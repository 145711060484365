import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useSpring, useChain, animated, interpolate } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper } from 'swiper/js/swiper.esm'
import BezierEasing from 'bezier-easing'
import InView from '../in-view'

import ChevronLeftSvg from '../../images/chevron-left.svg'
import ChevronRightSvg from '../../images/chevron-right.svg'
import Button from '../button'

import 'swiper/css/swiper.css'

export const fragment = graphql`
  fragment AboutCarouselFragment on WordPress_Page_Aboutfields {
    imageCarousel {
      buttonLink {
        ... on WordPress_Page {
          slug
        }
      }
      buttonText
      images {
        leftImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        rightImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const ImageCarousel = ({ buttonLink, buttonText, images }) => {
  const [frontSwiper, getFrontSwiper] = useState(null)
  const [backSwiper, getBackSwiper] = useState(null)
  const [animationToggle, setAnimationToggle] = useState(false)
  const carouselRef = useRef()

  const goNext = () => {
    if (frontSwiper !== null) {
      backSwiper.slidePrev()
      setTimeout(() => {
        frontSwiper.slideNext()
      }, 500)
    }
  }

  const goPrev = () => {
    if (frontSwiper !== null) {
      backSwiper.slideNext()
      setTimeout(() => {
        frontSwiper.slidePrev()
      }, 1000)
    }
  }

  // Amount to overlap slides
  const interleaveOffset = 0.9
  const swiperConfig = {
    Swiper,
    slidesPerView: 1,
    speed: 1200,
    loopAdditionalSlides: 3,
    watchSlidesProgress: true,
    roundLengths: true,
    loop: true,
    on: {
      progress() {
        const swiper = this
        for (let i = 0; i < swiper.slides.length; i += 1) {
          const slideProgress = swiper.slides[i].progress
          const innerOffset = swiper.width * interleaveOffset
          const innerTranslate = slideProgress * innerOffset
          swiper.slides[i].querySelector(
            '.front-swiper-image'
          ).style.transform = `translate3d(${innerTranslate}px,0, 0)`
        }
      },
      touchStart() {
        const swiper = this
        for (let i = 0; i < swiper.slides.length; i += 1) {
          swiper.slides[i].style.transition = ''
        }
      },
      setTransition(speed) {
        const swiper = this
        for (let i = 0; i < swiper.slides.length; i += 1) {
          swiper.slides[i].style.transition = `${speed}ms`
          swiper.slides[i].querySelector(
            '.front-swiper-image'
          ).style.transition = `${speed}ms cubic-bezier(0.77, 0, 0.175, 1)`
        }
      }
    }
  }

  const frontSwiperParams = {
    getSwiper: getFrontSwiper,
    ...swiperConfig
  }

  const backSwiperParams = {
    getSwiper: getBackSwiper,
    ...swiperConfig
  }

  const backImageIntroAnimationRef = useRef()
  const backImageIntroAnimation = useSpring({
    ref: backImageIntroAnimationRef,
    from: {
      transform: `translate3d(-101%, 0, 0)`
    },
    to: {
      transform: `translate3d(0%, 0, 0)`
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const backImageInnerIntroAnimationRef = useRef()
  const backImageInnerIntroAnimation = useSpring({
    ref: backImageInnerIntroAnimationRef,
    from: {
      transform: `translate3d(90%, 0, 0)`
    },
    to: {
      transform: `translate3d(0%, 0, 0)`
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const frontImageAnimationRef = useRef()
  const frontImageAnimation = useSpring({
    ref: frontImageAnimationRef,
    from: {
      transform: `translate3d(101%, 0, 0)`
    },
    to: {
      transform: `translate3d(0%, 0, 0)`
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const frontImageInnerAnimationRef = useRef()
  const frontImageInnerAnimation = useSpring({
    ref: frontImageInnerAnimationRef,
    from: {
      transform: `translate3d(-90%, 0, 0)`
    },
    to: {
      transform: `translate3d(0%, 0, 0)`
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const arrowTextAnimationRef = useRef()
  const arrowTextAnimation = useSpring({
    ref: arrowTextAnimationRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  })

  useChain(
    animationToggle
      ? [
          backImageIntroAnimationRef,
          backImageInnerIntroAnimationRef,
          frontImageAnimationRef,
          frontImageInnerAnimationRef,
          arrowTextAnimationRef
        ]
      : [],
    [0, 0, 0.5, 0.5, 1]
  )

  return (
    <Outer ref={carouselRef}>
      <InView element={carouselRef.current} toggle={animationToggle} setToggle={setAnimationToggle}>
        <ButtonWrap>
          <Button type="link" to={buttonLink.slug} text={buttonText} />
        </ButtonWrap>
        <Arrows style={arrowTextAnimation}>
          <>
            <ArrowLeft onClick={() => goPrev()}>
              <ChevronLeftSvg />
            </ArrowLeft>
            <ArrowRight onClick={() => goNext()}>
              <ChevronRightSvg />
            </ArrowRight>
          </>
        </Arrows>
        <ImageBackContainer>
          <ImageBackOuter style={backImageIntroAnimation}>
            <ImageBackInner style={backImageInnerIntroAnimation}>
              <ImageBack center speed={0}>
                <ReactIdSwiperCustom {...backSwiperParams}>
                  {images.map((image, i) => (
                    <div key={i}>
                      <GatsbyImage
                        className="front-swiper-image"
                        key={i}
                        fluid={image.leftImage.imageFile.childImageSharp.fluid}
                        alt={image.leftImage.alt}
                        loading="eager"
                      />
                    </div>
                  ))}
                </ReactIdSwiperCustom>
              </ImageBack>
            </ImageBackInner>
          </ImageBackOuter>
        </ImageBackContainer>
        <ImageFrontContainer>
          <ImageFrontOuter style={frontImageAnimation}>
            <ImageFrontInner style={frontImageInnerAnimation}>
              <ImageFront center speed={1}>
                <ReactIdSwiperCustom {...frontSwiperParams}>
                  {images.map((image, i) => (
                    <div key={i}>
                      <GatsbyImage
                        className="front-swiper-image"
                        key={i}
                        fluid={image.rightImage.imageFile.childImageSharp.fluid}
                        alt={image.rightImage.alt}
                        loading="eager"
                      />
                    </div>
                  ))}
                </ReactIdSwiperCustom>
              </ImageFront>
            </ImageFrontInner>
          </ImageFrontOuter>
        </ImageFrontContainer>
      </InView>
    </Outer>
  )
}

export default ImageCarousel

const Outer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 83.5%;
  z-index: 1;

  @media (max-width: 1260px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (max-width: 650px) {
    flex-direction: column-reverse;
    margin-bottom: 50%;
    padding: 0;
  }
`

const ButtonWrap = styled(animated.div)`
  position: absolute;
  top: 73%;
  right: 35.5%;

  @media (max-width: 650px) {
    top: 150%;
    width: 100%;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }
`

const ImageBackContainer = styled.div`
  position: relative;
  width: 67.5%;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    padding-bottom: 61%;
  }

  @media (max-width: 650px) {
    width: calc(100% - 30px);
  }
`

const ImageBackOuter = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const ImageBackInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ImageBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .swiper-container {
    height: 100%;
  }

  .swiper-wrapper {
    height: 100%;
    will-change: transform;
    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .swiper-slide,
  .swiper-slide-duplicate {
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colours.darkGrey};
    overflow: hidden;
  }
`

const GatsbyImage = styled(Img)`
  width: 100%;
  height: 100%;
  will-change: transform;

  img {
    opacity: 1 !important;
  }
`

const ImageFrontContainer = styled.section`
  position: relative;
  height: 80%;
  width: 32.5%;
  margin-top: 28%;
  overflow: hidden;
  pointer-events: none;
  transform-origin: right;
  z-index: 5;

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  @media (max-width: 650px) {
    position: absolute;
    right: 0;
    top: 50%;
    width: 50%;
    margin-top: 0;
  }
`

const ImageFrontOuter = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const ImageFrontInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ImageFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .swiper-container {
    height: 100%;
  }

  .swiper-wrapper {
    height: 100%;
    will-change: transform;
    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .swiper-slide,
  .swiper-slide-duplicate {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`

const Arrows = styled(animated.nav)`
  position: absolute;
  display: flex;
  align-items: center;
  top: 36%;
  right: 30%;
  transform: translateX(100%);
  z-index: 20;

  @media (max-width: 1000px) {
    top: 33%;
  }

  @media (max-width: 800px) {
    top: 30%;
  }

  @media (max-width: 650px) {
    top: 109%;
    right: 85%;
  }

  svg {
    width: 12px;
    height: 23px;
    fill: ${props => props.theme.colours.orange};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1.2rem 2rem;

    @media (max-width: 800px) {
      padding: 1.2rem 2rem;
    }
  }
`

const ArrowLeft = styled.div``

const ArrowRight = styled.div``
