import React, { useContext, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper, Pagination } from 'swiper/js/swiper.esm'
import { TransitionContext } from './context/transition-context'
import { ScrollbarContext } from './context/scrollbar-context'

import BackgroundImage from './background-image'

import 'swiper/css/swiper.css'

const truncate = string => {
  const splitString = string.split(' ')

  if (splitString.length > 8) {
    const truncated = splitString.slice(0, 8)
    truncated.push('...')
    return truncated.join(' ')
  }

  return string
}

const SinglePress = ({ scrollToPress }) => {
  const [scrollbar] = useContext(ScrollbarContext)
  const ref = useRef()
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  const { wordPress } = useStaticQuery(
    graphql`
      query {
        wordPress {
          press(first: 24) {
            edges {
              node {
                title
                slug
                pressFields {
                  meta {
                    source
                  }
                }
              }
            }
          }
          globalOptions {
            globalFields {
              press {
                backgroundImage {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 70) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    if (scrollToPress === true && scrollbar) {
      scrollbar.scrollIntoView(ref.current)
    }
  }, [scrollToPress, scrollbar])

  // Chuck an array by a size permeter
  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    )

  // Check the posts into sets of four
  const chunkedPosts = chunk(wordPress.press.edges, 4)

  const swiperConfig = {
    Swiper,
    modules: [Pagination],
    speed: 1,
    allowTouchMove: false,
    pagination: {
      el: '.swiper-pagination-container',
      clickable: true,
      renderBullet: (index, className) =>
        `<div class="${className}"><span>0${index + 1}</span></div>`
    }
  }

  return (
    <Outer ref={ref}>
      <BackgroundImage
        image={
          wordPress.globalOptions.globalFields.press.backgroundImage.imageFile.childImageSharp.fluid
        }
      />
      <Title>In the press</Title>
      <Line />
      <PressSwiper>
        <ReactIdSwiperCustom {...swiperConfig}>
          {chunkedPosts.map((item, i) => (
            <SlideInner key={i}>
              {item.map((post, index) => (
                <Post
                  key={index}
                  to={`/press/${post.node.slug}`}
                  onClick={event => {
                    event.preventDefault()
                    setTransitionActive(true)
                    setTimeout(() => {
                      navigate(`/press/${post.node.slug}`)
                      setTransitionActive(false)
                    }, 500)
                  }}
                >
                  <PostTitle>{truncate(post.node.title)}</PostTitle>
                  <PostSource>{post.node.pressFields.meta.source}</PostSource>
                </Post>
              ))}
            </SlideInner>
          ))}
        </ReactIdSwiperCustom>
        <div className="swiper-pagination-container" />
      </PressSwiper>
    </Outer>
  )
}

export default SinglePress

const Outer = styled.section`
  position: relative;
  padding: 17rem 16.5%;

  @media (max-width: 1260px) {
    padding: 17rem 12.5%;
  }

  @media (max-width: 650px) {
    padding: 65px 30px;
  }
`

const Title = styled.p`
  font-family: ${props => props.theme.fonts.butler};
  font-size: 7rem;
  color: ${props => props.theme.colours.orange};
  letter-spacing: -0.1rem;
`

const Line = styled.div`
  width: 6.7rem;
  height: 1px;
  background-color: #707070;
`

const PostTitle = styled.p`
  font-family: ${props => props.theme.fonts.butler};
  font-size: 3rem;
  color: #fff;
  transition: color 0.8s ease;
`

const PostSource = styled.p`
  font-family: ${props => props.theme.fonts.maison};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #858585;

  &:before {
    content: '►';
    font-family: ${props => props.theme.fonts.maison};
    font-size: 1.2rem;
    color: ${props => props.theme.colours.orange};
    margin-right: 0.5rem;
  }
`

const PressSwiper = styled.div`
  margin: 5rem 0 0;

  .swiper-slide {
    font-family: ${props => props.theme.fonts.butler};
    font-size: 3rem;
    color: #fff;
  }

  .swiper-pagination-container {
    display: flex;
    justify-content: center;
  }

  .swiper-pagination-bullets {
    margin-top: 10rem;
  }

  .swiper-pagination-bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.8rem;
    height: 5.8rem;
    border-radius: 0;
    background-color: transparent;
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #fff;
    margin: 0 0.6rem !important;
    opacity: 1;
    transition: background-color 0.8s ease, opacity 0.8s ease;

    &:hover {
      opacity: 0.5;
    }

    span {
      margin: 0.2rem 0 0 0.4rem;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.colours.orange};

    &:hover {
      opacity: 1;
    }
  }

  .swiper-slide {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 0.25s ease;

    a {
      color: #fff;
      text-decoration: none;
      opacity: 0;
      transition: opacity 1s ease;

      &:nth-child(2) {
        transition-delay: 0s;
      }

      &:hover {
        ${PostTitle} {
          color: ${props => props.theme.colours.orange};
        }
      }
    }
  }

  .swiper-slide-active {
    opacity: 1;
    transition: opacity 0s ease;
    transition-delay: 0.25s;

    a {
      opacity: 1;
      transition: opacity 1s ease;

      &:nth-child(1) {
        transition-delay: 0.2s;
      }

      &:nth-child(2) {
        transition-delay: 0.4s;
      }

      &:nth-child(3) {
        transition-delay: 0.6s;
      }

      &:nth-child(4) {
        transition-delay: 0.8s;
      }
    }
  }

  .swiper-wrapper {
    transition-delay: 0.25s;
    transition-duration: 0s !important;
  }
`

const SlideInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Post = styled(Link)`
  width: calc(50% - 3rem);
  margin-bottom: 3rem;

  @media (max-width: 650px) {
    width: 100%;
  }
`
